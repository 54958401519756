import { useState } from 'react';

import { windowCheck } from 'utils/helpers';
import { getLDFlagVariation } from 'utils/launch-darkly/useLD';

export function useFlagAwait(flag: string) {
  const [flagValue, setFlagValue] = useState<undefined | boolean | string>(undefined);

  windowCheck && getLDFlagVariation(flag).then(flagValue => {
    const ldFlag: boolean | string = flagValue;
    setFlagValue(ldFlag);
  });

  return flagValue;
}