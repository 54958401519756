import FontIcon from 'components/icons';

import { v4 as uuid } from 'uuid';

import 'components/content-blocks/styles/two-column-grid.scss';

type TwoColumnGridProps = {
  heading: string;
  body: string;
  link: {
    title: string;
    url: string;
  };
  list: {
    feature: string;
    icon: string;
  }[];
};

const TwoColumnGrid = ({heading, body, link, list}: TwoColumnGridProps) => {
  return (
    <section
      className="two-column-grid-section"
      data-section="twoColumnGrid"
    >
      <h2>{heading}</h2>
      <p>{body} <a href={link.url}>{link.title}</a></p>
      <ul>
        {list.map((item) => {
          return (<li key={uuid()}>
            <FontIcon className="item-icon" icon={item.icon} />
            <span className="item-text">{item.feature}</span>
            <FontIcon className="item-check" icon="checkmark" />
          </li>);
        })}
      </ul>
    </section>
  );
}

export default TwoColumnGrid;