import { useModal } from 'react-modal-hook';

import ACFImage from 'types/ACFImage';

import ScheduleDemoModal from 'components/modal/schedule-demo/schedule-demo-modal';
import Signups from 'components/modal/signups';

import classnames from 'classnames';

import './styles/pricing.scss';

type HeroPricingProps = {
  background_desktop_image: ACFImage;

  copy: string;
  schedule_demo: {
    button_image: ACFImage;
    button_text: string;
  };
  sub_title: string;
  title: string;
  signup: {
    modal_submit_button_text: string;
    modal_title: string;
    launch_modal_button_text: string;
  };
  pricingFlagValue: string | boolean | undefined;
};

export const HeroPricing = ({
  sub_title,
  copy,
  schedule_demo,
  background_desktop_image,
  title,
  signup,
  pricingFlagValue,
}:HeroPricingProps) => {
  const is2025pricing = pricingFlagValue === 'pricing-2025';
  const [showScheduleDemoModal, hideScheduleDemoModal] = useModal(() => (
    <ScheduleDemoModal isOpen={ true } onHide={ hideScheduleDemoModal } />
  ));
  return (
    <section
      className="pricing-hero-section hero-section"
      data-section="pricingHero"
    >
      <div className="container container-large">
        <div className={classnames("hero-wrapper is-flex-desktop", {'experiment-loading': pricingFlagValue === undefined})}>
            {is2025pricing ?
              <header className="pricing-2025">
                <h1 className="subtitle">{sub_title}</h1>
                <h2 className="title">{title}</h2>
                <div className="signup-cta-container">
                  <Signups
                    signupModalLaunch={ signup.launch_modal_button_text }
                    signupModalSubmit={ signup.modal_submit_button_text }
                    fieldLayout="row"
                    showInitFields={ true }
                    buttonInline={ true }
                  />
                </div>
                <p>Try everything, free, for 14 days. Then choose the plan that works best for your business.</p>
              </header>
            :
              <header>
                <h1 className="subtitle">{sub_title}</h1>
                <h2 className="title" dangerouslySetInnerHTML={ { __html: copy } } />
                <button
                  type="button"
                  onClick={ showScheduleDemoModal }
                  className="button button-link is-rounded"
                >
                  {schedule_demo.button_text}
                </button>
              </header>
            }
        </div>
      </div>
      <img
        className="background-image"
        src={ is2025pricing ? 'https://marketing-assets.wheniwork-production.com/2025/02/10131649/Pricing-Hero-2025.svg' : background_desktop_image.source_url }
        role="presentation"
      />
    </section>
  );
};
