import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import classnames from 'classnames';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { flatten } from 'lodash';

import PricingCardSlide, { type PricingCard } from './slides/pricing-card';
import PricingCardSlide2025, { type PricingCard2025 } from './slides/pricing-card-2025';

import { v4 as uuid } from 'uuid';

type PricingTiersProps = {
  activateAttendance: boolean,
  fdt285?: string | boolean,
  fdt387?: string | boolean,
  tiers: PricingCard[] | PricingCard2025[],
  toggleAttendance: () => void
}

const PricingTiers = ({
  activateAttendance,
  toggleAttendance,
  tiers,
  fdt285,
  fdt387,
}: PricingTiersProps) => {
  const breakpoints = useBreakpoint();

  const [itemIndex, setItemIndex] = useState(0);

  const is2025Pricing = fdt285 === 'pricing-2025';

  const trimTitle = title => {
    if (!is2025Pricing) {
      return title;
    }
    const titlePieces = title.split(' ');
    return `${titlePieces[0]} ${titlePieces[1]}`;
  };

  const tabNav = () => {
    return <nav className={classnames("wiw-tabs-nav", {'pricing-2025': is2025Pricing})}>
      {tiers.map((tier, index) => {
        return (
          <button
            key={ index }
            role="button"
            className={ `button wiw-tabs-link is-rounded ${
              index === itemIndex ? 'active' : ''
            }` }
            onClick={ () => setItemIndex(index) }
          >
            {trimTitle(tier.title ? tier.title : tier)}
          </button>
        );
      })}
    </nav>;
  };

  const selectNav = () => {
    return <nav className="wiw-tabs-nav">
      <div className="select is-rounded">
        <select value={ itemIndex } onChange={ e => setItemIndex(parseInt(e.currentTarget.value)) }>
          {tiers.map((tier, index) => {
            return <option key={ index } value={ index }>{ tier.title ? tier.title : tier }</option>;
          })}
        </select>
      </div>
    </nav>;
  };

  if (breakpoints.sm) {
    return (
      <div className="columns slider-container pricing-tiers-slider">
        { !fdt387 ? tabNav() : selectNav() }
        <SwipeableViews
          onChangeIndex={ i => setItemIndex(i) }
          style={ { padding: '0px calc(42% - 150px)' } }
          springConfig={ {
            easeFunction: 'cubic-bezier(0, 0, 0.24, 0.96)',
            duration: '.8s',
            delay: '0s',
          } }
          enableMouseEvents
          index={ itemIndex }
        >
          {tiers?.map(tier => {
            return is2025Pricing ?
              <PricingCardSlide2025
                key={ uuid() }
                tier={ tier }
              />
            :
              <PricingCardSlide
                key={ uuid() }
                toggleAttendance={ toggleAttendance }
                activateAttendance={ activateAttendance }
                tier={ tier }
                fdt285={ fdt285 }
                fdt387={ fdt387 }
              />;
          })}
        </SwipeableViews>
        <div className="slides-dots">
          {new Array(flatten(tiers?.map(data => data)).length)
            .fill(undefined)
            .map((_, index) => (
              <div
                key={ index }
                className={ `slides-dot ${
                  index === itemIndex ? 'slides-dot-fill' : 'slides-dot-empty'
                }` }
              />
            ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className={ classnames('columns tiers-container', { 'has-free-plan': fdt387, 'pricing-2025': is2025Pricing }) }>
        {tiers?.map(tier => {
          return is2025Pricing ?
            <PricingCardSlide2025
              key={ uuid()}
              tier={ tier }
            />
          :
            <PricingCardSlide
              key={ uuid() }
              toggleAttendance={ toggleAttendance }
              activateAttendance={ activateAttendance }
              tier={ tier }
              fdt285={ fdt285 }
              fdt387={ fdt387 }
            />;
        })}
      </div>
    );
  }
};

export default PricingTiers;
