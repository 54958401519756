import ReactModal from 'react-modal';
import { graphql, useStaticQuery } from 'gatsby';

import TwoColumnGrid from 'components/content-blocks/two-column-grid';
import CloseBtn from '../buttons/button-close';

import './styles/packaging-features.scss';

const PackagingFeaturesModal = ({ isOpen, onHide}) => {
  const data = useStaticQuery(graphql`
    {
      allWordpressAcfOptions {
        edges {
          node {
            options {
              packaging_2025 {
                body
                heading
                link {
                  title
                  url
                }
                list {
                  feature
                  icon
                }
              }
            }
          }
        }
      }
    }
  `);

  const packaging2025 = data.allWordpressAcfOptions.edges[0].node.options.packaging_2025;
  
  return (
    <ReactModal
      className="default-modal-wrapper packaging-features-modal"
      isOpen={ isOpen }
      onRequestClose={ onHide }
      overlayClassName="default-modal-overlay"
      shouldCloseOnOverlayClick={ true }
    >
      <CloseBtn onClick={ onHide } />
      <TwoColumnGrid { ...packaging2025 } />
    </ReactModal>
  )
};

export default PackagingFeaturesModal;